<!-- 
 * @page: 挂号缴费详情
 * @Author: 张阳帅
 * @Date: 2024-10-09 17:52:37
 * @LastEditTime: 2024-10-09 17:52:37
 * @LastEditors: Please set LastEditors
-->

<template>
  <div class="page-wrap">
    <div class="appointDetail">
      <!-- type=1 预约 type=2挂号 -->
      <!-- <div class="appointment-head" :class="newappointmentStatus ? '' : 'fail'">
          <template v-if="newappointmentStatus">
            <div class="subject">
              预约成功待付款
            </div>
          </template>
          <template v-else>
            <div class="subject">
              <img class="img" :src="require('../../../assets/img/fail_icon.png')">
              已取消预约
            </div>
            <div>取消原因：{{ detail.qxyy }}</div>
          </template>
        </div> -->
      <div class="appointment-head">
        <div class="subject">
          <img v-if="detail.yyzt == '1'" class="img" :src="require('../../../assets/img/sucess_icon.png')">
          <img v-else class="img" :src="require('../../../assets/img/fail_icon.png')">
          <div>{{ detail.yyztmc }}</div>
        </div>
        <!-- <img class="img" :src="require('../../../assets/img/sucess_icon.png')">
            <img class="img" :src="require('../../../assets/img/fail_icon.png')"> -->
      </div>
      <div class="appointmentDetail-body">
        <div class="info">
          <div class="info-item">
            <span class="info-item-title">就诊人</span>
            <div class="con">
              {{ detail.jzrxm }} {{ detail.jzrxbmc }} {{ detail.age }}岁
            </div>
          </div>
          <div class="info-item">
            <span class="info-item-title">医生姓名</span>
            <div class="con">
              {{ detail.ysxm }}
            </div>
          </div>
          <div class="info-item">
            <span class="info-item-title">就诊科室</span>
            <div class="con">
              {{ detail.ksmc }}
            </div>
          </div>
          <div class="info-item">
            <span class="info-item-title">就诊日期</span>
            <div class="con">
              {{ detail.hyrq }} {{ detail.sxwbzmc }}
            </div>
          </div>
          <div class="info-item">
            <span class="info-item-title">就诊时段</span>
            <div class="con">
              {{ detail.fskssj }}-{{ detail.fsjssj }}
            </div>
          </div>
          <div class="info-item">
            <span class="info-item-title">挂号费用</span>
            <div class="con">
              <span :class="appointmentStatus ? 'price' : ''">¥ {{ detail.ghf }}</span>
            </div>
          </div>
          <div class="info-item">
            <span class="info-item-title">提交日期</span>
            <div class="con">
              {{ detail.createTime }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
  import { gjcModel } from '../../../api/gjcApi'
  
  export default {
      data(){
          return{
              type: '1',
              detail:{},
              ill:'',
              newappointmentStatus: true,
              typeStaus:false,
              appointmentStatus: true, // 预约状态： false->已取消
              payStaus:false,
              tabIndex: null,
              qxyy:"",
              errorName:''
          }
      },
      mounted(){
          this.getDetail()
      },
      methods:{
          getDetail(){
              gjcModel.getAppointRecorDetailData(this.$route.query.id).then(res=>{
                  this.detail = res.data       
              })
          }
      }
  }
  </script>
  <style scoped lang='less'>
  .payment-tips{
      text-align: right;
      width: 686px;
      box-sizing: border-box;
      margin: 0 auto 0;
      border-radius: 12px;
      color: #f56c6c;
      font-size: 22px;
  }
  div {
      box-sizing: border-box;
  }
  .page-wrap {
      padding-bottom: 108px;
      .orderpay_page{
          background-color: #f1f1f1;
      }
  }
  .appointDetail {
      padding: 0 32px;
      overflow: scroll;
      .appointment-head {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 202px;
          padding: 40px;
          line-height: 33px;
          font-size: 26px;
          color: #fff;
          text-align: center;
          background: #1c9cf6;
  
          &.fail {
              background: #b5b5b5;
          }
  
          .subject {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 33px;
            font-size: 32px;
            line-height: 40px;
    
          .img {
            width: 44px;
            height: 44px;
            margin-right: 16px;
          }
        }
      }
      .appointmentDetail-body {
        position: relative;
        z-index: 2;
        padding: 153px 0px 0px;
    
        .info {
          padding: 40px 32px;
          border-radius: 20px;
          background: #fff;
    
          .info-number {
            margin-bottom: 40px;
            font-weight: bold;
            font-size: 28px;
            color: #3d3d3d;
            text-align: center;
          }
    
          .info-item {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 20px 0;
            line-height: 40px;
            font-size: 28px;
            color: #3d3d3d;
            &.info-item__tip{
              padding-bottom: 0;
            }
    
            &.info-item-s {
              display: block;
    
              .label {
                margin-bottom: 24px;
                font-weight: bold;
              }
              .upImage {
                width: 120px;
                height: 120px;
              }
            }
    
            .info-item-title {
              font-weight: bold;
            }
    
            .con {
              flex: 1;
              color: #666;
              text-align: right;
    
              .name {
                margin-right: 24px;
              }
    
              .price {
                color: #dc3030;
              }
            }
    
            .cons {
              flex: 1;
              color: #666;
              text-align: right;
              width: 300px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
    
            .ill-desc {
              line-height: 33px;
              font-size: 26px;
              color: #999;
            }
          }
    
          .info-item-upload {
            align-items: flex-start;
    
            .con {
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }
    
            .pic,
            .more {
              width: 120px;
              height: 120px;
              border-radius: 8px;
            }
    
            .pic {
              margin-right: 55px;
              background: #ccc;
            }
    
            .more {
              display: flex;
              align-items: center;
              justify-content: center;
              background: #eee;
    
              .img {
                width: 34px;
                height: 6px;
              }
            }
          }
        }
      }
    
      .reason-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 0px 32px;
        margin: 40px 0;
        .reason-item {
          min-width: 160px;
          height: 52px;
          margin: 12px 22px;
          line-height: 52px;
          font-size: 28px;
          color: #666;
          text-align: center;
          background: #f2f2f2;
    
          &.active {
            color: #fff;
            background: #1c9cf6;
          }
        }
      }
      .van-dialog {
        width: 686px;
      }
      /deep/.van-dialog--round-button .van-dialog__confirm {
      }
      /deep/.van-dialog--round-button .van-dialog__cancel {
      }
      /deep/.van-goods-action-button--first {
        border-radius: 12px;
      }
      /deep/.van-goods-action-button--last {
        border-radius: 12px;
      }
    }
    .modal-foot {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 98px;
      line-height: 98px;
      background: #f2f2f2;
      display: flex;
      justify-content: space-around;
      align-items: center;
      z-index: 999;
      .btn {
        width: 322px;
        height: 68px;
        line-height: 68px;
        font-size: 30px;
        text-align: center;
        border-radius: 12px;
      }
      .btn-default {
        color: #666;
        background: #ddd;
      }
      .btn-primary {
        color: #fff;
        background: #1c9cf6;
      }
    }
  </style>
  